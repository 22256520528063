import React, { useState } from 'react';
import { Container, Grid, Box, IconButton, Button } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import './Gallery.css'; 

const Gallery = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(null); 

    const handleImageClick = (image) => {
        setSelectedImage(image); 
    };

    const handleCloseModal = () => {
        setSelectedImage(null); 
    };

    return (
        <Container maxWidth="lg" className="gallery-container">
            <Box sx={{ position: 'absolute', top: 20, left: 20, zIndex: 1000 }}>
                <Button
                    onClick={() => window.history.back()} 
                    sx={{
                        color: "white",
                        backgroundColor: "#F07B0B",
                        borderRadius: "25px",
                        padding: "10px 20px",
                        '&:hover': {
                            backgroundColor: "white",
                            color: "#F07B0B",
                        },
                    }}
                >
                    <ArrowBackIcon sx={{ mr: 1 }} />
                </Button>
            </Box>

            <Grid container spacing={2} sx={{ marginTop: "60px" }}>
                {images.map((image, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box className="gallery-item">
                            <img 
                                src={image} 
                                alt={`Gallery image ${index + 1}`} 
                                className="gallery-image"
                                onClick={() => handleImageClick(image)} 
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>

            {selectedImage && (
                <Box className="modal-overlay" onClick={handleCloseModal}>
                    <Box className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <IconButton 
                            sx={{ position: 'absolute', top: 10, right: 10, color: 'red' }} 
                            onClick={handleCloseModal}
                        >
                            &times;
                        </IconButton>
                        <img src={selectedImage} alt="Selected" className="modal-image" />
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default Gallery;
