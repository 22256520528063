import React from 'react';
import './Navbar.css';
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, IconButton, useScrollTrigger, Slide, useMediaQuery, useTheme, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerComponent from "../Drawer/DrawerComponent";

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

export default function Navbar(props) {
    const navigate = useNavigate();
    const navigateHome = () => {
        navigate('/');
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar position="fixed" color="default" elevation={0} sx={{ background: 'transparent', boxShadow: 'none' }}>
                    <Toolbar sx={{ justifyContent: isMobile ? 'flex-end' : 'space-between' }}>
                        {!isMobile && (
                            <Typography variant="h6" sx={{ flexGrow: 1, cursor: 'pointer', fontFamily: 'Roboto, sans-serif' }} onClick={navigateHome}>
                                ONDZA ESSEY
                            </Typography>
                        )}
                        {isMobile ? (
                            <IconButton color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <Box>
                                <Button color="inherit" onClick={() => navigate('/about')} sx={{ fontFamily: 'Roboto, sans-serif' }}>About</Button>
                                <Button color="inherit" onClick={() => navigate('/contact')} sx={{ fontFamily: 'Roboto, sans-serif' }}>Contact</Button>
                                <Button color="inherit" onClick={() => navigate('/gallery')} sx={{ fontFamily: 'Roboto, sans-serif' }}>Gallery</Button>

                            </Box>
                        )}
                        {isMobile && <DrawerComponent open={drawerOpen} onClose={handleDrawerClose} />}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
        </>
    );
}
