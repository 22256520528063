import React, { useContext, useState, useRef, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import ReservationForm from '../Contact/ReservationForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import './ActivityPage.css';
import { ThemeContext } from '@emotion/react';

const ActivityPage = (props) => {
    const location = useLocation();
    const { title, description, images, reservationType } = location.state || {};
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const scrollContainerRef = useRef(null);

    const goBack = () => {
        navigate('/');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return (
        <Container className="container" maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                        <Button
                            onClick={goBack}
                            sx={{
                                position: 'absolute',
                                top: 40,
                                left: 20,
                                zIndex: 1000,
                                color: 'white',
                                backgroundColor: '#F07B0B',
                                borderRadius: '25px',
                                padding: '16px 30px',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: '#F07B0B',
                                    border: '2px solid #F07B0B',
                                },
                            }}
                        >
                            <ArrowBackIcon sx={{ mr: 1, color: 'white' }} />
                        </Button>
                        <Typography variant="h4" component="h1" sx={{ flexGrow: 1, color: 'text.primary', textAlign: 'center' }}>
                            {props.title}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box 
                    ref={scrollContainerRef} className="carousel">
                        <img src={images.img1} alt="activity image 1" />
                        <img src={images.img2} alt="activity image 2" />
                        <img src={images.img3} alt="activity image 3" />
                    </Box>
                    {isMobile && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button onClick={scrollLeft} sx={{ color: '#F07B0B' }}>
                                <ArrowBackIosIcon />
                            </Button>
                            <Button onClick={scrollRight} sx={{ color: '#F07B0B' }}>
                                <ArrowForwardIcon />
                            </Button>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} md={6} className="sticky-header">
                    <Typography variant="h4" gutterBottom sx={{ color: 'text.primary' }}>
                        {title}
                    </Typography>
                    <hr />
                    <Typography variant="h5" gutterBottom sx={{ fontFamily: 'f37bergman', color: 'text.primary' }}>
                        {description}
                    </Typography>
                    <ReservationForm reservationType={reservationType} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default ActivityPage;
