import React from "react";
import "./contactcomponent.css";
import Socials from "../Socials";
import { Button } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material"; 
import { useNavigate } from "react-router-dom";

const ContactComponent = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/'); 
    };

    return (
        <div className="contact-main-container">
            <Button
                onClick={goBack}
                sx={{
                    position: "absolute",
                    top: { xs: 20, md: 40 }, 
                    left: { xs: 10, md: 20 },
                    zIndex: 1000,
                    color: "#090c25",
                    backgroundColor: "transparent", 
                    border: "none", 
                    cursor: "pointer", 
                }}
            >
                <ArrowBackIcon sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }} /> 
            </Button>

            <section id="formulaire">
                <h1>Get in touch</h1>
                <hr />
                <form name="Form1" action="" method="POST" encType="multipart/form-data">
                    <div className="first-line">
                        <div>
                            <label>Your Name (required)<br />
                                <span>
                                    <input type="text" name="your-name" size="80" required />
                                </span>
                            </label>
                        </div>
                        <div>
                            <label>Your Email (required)<br />
                                <span>
                                    <input type="email" name="your-email" size="80" required />
                                </span>
                            </label>
                        </div>
                        <div>
                            <label>Phone<br />
                                <span>
                                    <input type="tel" pattern="(\+389)[\-]\d{3}[\-]\d{3}" name="your-phone" size="80" />
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="third_line">
                        <label>Your Message<br />
                            <span>
                                <textarea name="your-message" cols="240" rows="4"></textarea>
                            </span>
                        </label>
                    </div>
                    <button className="button button1" type="submit" name="send">Send</button>
                </form>
            </section>
            <Socials />
        </div>
    );
}

export default ContactComponent;
