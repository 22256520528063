import React, { useEffect } from "react";
import Socials from "../Socials";
import { Typography, Box, Container, Button } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./about.css"; // custom CSS file

export default function About() {
    const navigate = useNavigate();

    useEffect(() => {
        if (document) {
            const stylesheet = document.createElement("link");
            stylesheet.rel = "stylesheet";
            stylesheet.href = "https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css";
            document.head.appendChild(stylesheet);
        }
    }, []);

    const goBack = () => {
        navigate('/');
    };

    return (
        <Container className="container mx-auto p-6">
            <Button
                onClick={goBack}
                startIcon={<ArrowBackIcon />}
                sx={{
                    position: "absolute",
                    top: 40,
                    left: 20,
                    zIndex: 1000,
                    color: "white", // Text color
                    backgroundColor: "#F07B0B", // Orange background
                    borderRadius: "25px", // Rounded corners
                    padding: "16px 30px", // Padding for a better look
                    '&:hover': {
                        backgroundColor: "white", // Change to white on hover
                        color: "#F07B0B", // Change text color to orange on hover
                        border: "2px solid #F07B0B", // Add border on hover
                    },
                }}
            >
            </Button>

            <Container className="about-section bg-white shadow-lg rounded-lg p-8 my-12">
                {/* First Image Section */}
                <Container className="image-container text-center">
                    <img    
                        id="portrait-1"
                        src={require("../../assets/main-pic.jpeg")}
                        alt="portrait-1.png"
                        loading="lazy"
                        className="about-image w-64 h-64 object-cover mx-auto rounded-full border-4 border-orange-500"
                    />
                </Container>

                {/* Main Content Section */}
                <Box className="about-content text-center mt-8">
                    <Typography
                        variant="h4"
                        className="text-4xl font-semibold text-orange-600 mb-4"
                    >
                        What HOSCA is about
                    </Typography>
                    <hr className="border-2 border-orange-600 w-1/4 mx-auto mb-6" />

                    <Typography className="text-lg text-gray-700 leading-relaxed">
                        An African-based culinary ecosystem, done through private dining and workshops.
                    </Typography>
                </Box>

                {/* Second Image Section */}
                <Box className="image-container text-center mt-10">
                    <img
                        id="portrait-2"
                        src={require("../../assets/IMG_2989.jpg")}
                        alt="portrait-2.png"
                        loading="lazy"
                        className="about-image w-64 h-64 object-cover mx-auto rounded-full border-4 border-orange-500"
                    />
                </Box>

                {/* Expanded Content Section */}
                <Container className="about-description mt-12 text-gray-800 text-center">
                    <Typography className="text-lg mb-6 leading-relaxed">
                        Ondza Essey derives its name from the Téké language, a traditional language spoken in the Anziku Kingdom, now Congo. "Ondza" translates to "eat well" or "good food" in Téké. This linguistic choice reflects the founder's deep connection to their roots and historical background.
                    </Typography>
                    <Typography className="text-lg mb-6 leading-relaxed">
                        Established in 2022, Ondza Essey is dedicated to showcasing both Congolese and broader African cuisine, introducing customers to the rich and diverse flavors of African gastronomy while incorporating selected influences.
                    </Typography>

                    <Typography className="text-lg mb-6 leading-relaxed">
                        At Ondza Essey, we take pride in culinary excellence, cultural authenticity, and customer satisfaction. We strive to create unforgettable culinary journeys that celebrate the richness of African culinary heritage through our various services.
                    </Typography>
                </Container>

                {/* Socials Component */}
                <Box className="socials mt-10">
                    <Socials />
                </Box>
            </Container>
        </Container>
    );
}
