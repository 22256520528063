import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Home from "./routes/Home/Home";
import ContactComponent from "../src/components/Contact/ContactComponent";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import React from 'react';
import ActivityPage from "./components/ActivityPage/ActivityPage";
import { ThemeProvider } from '@mui/material/styles';
import {luxuriousTheme } from '../src/context/theme';
import Gallery from "./components/Gallery/Gallery";
import image1 from '../src/assets//Blades/IMG_1738.jpeg'; 
import image2 from '../src/assets/F&B/DJI_20220904_132710_239.jpg';
import image3 from '../src/assets/F&B/DJI_20220226_190708_894.jpg';
import image4 from '../src/assets/F&B/DJI_20220913_173204_569.jpg';
import image5 from '../src/assets/F&B/IMG_0233.jpeg';
import image6 from '../src/assets/F&B/IMG_1599.jpeg';
import image7 from '../src/assets/F&B/IMG_1598.jpeg';
import image8 from '../src/assets/F&B/IMG_2848.jpg';
import image9 from '../src/assets/F&B/IMG_1214.jpeg';

export default function App() {

  const imageArray = [
    image1, image2,image3,
    image4,image5,image6,
    image7,image8, image9
  ];
  return (
    <ThemeProvider theme={luxuriousTheme}>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />

            <Route path="/contact" element={<ContactComponent />} />
            <Route path="/activities/:category" element={<ActivityPage />} />
            <Route path="/gallery" element={<Gallery images={imageArray} />} /> 

          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}